import React, { FC, useEffect, useMemo, useRef } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { graphql } from "gatsby";
import { GetFocusLayoutImagesQuery } from "@generated/graphql";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { DefaultPageProps } from "@layouts/types";
import { BaseFooter } from "@components/base-footer/base-footer";
import { BaseNavigation } from "@components/base-navigation/base-navigation";
import { StaticAssetsProvider } from "@providers/static-assets";
import { HTMLHead } from "@components/html-head/html-head";
import { GatsbyImage } from "gatsby-plugin-image";

export const query = graphql`
  query GetFocusLayoutImages($mdxId: String!) {
    mdx(id: { eq: $mdxId }) {
      body
    }
    allFile {
      nodes {
        publicURL
        relativePath
        childImageSharp {
          id
          gatsbyImageData(placeholder: BLURRED, quality: 90)
        }
      }
    }
  }
`;

const FocusPageLayout: FC<DefaultPageProps<GetFocusLayoutImagesQuery>> = (
  props
) => {
  const { pageContext, data, children } = props;

  const heroSection = useRef<HTMLDivElement>(null);
  const heroImageRef = useRef<HTMLImageElement>(null);

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    // Parallax effect
    gsap.fromTo(
      heroImageRef.current,
      {
        scale: 1.2, // Scale to 120%, so the image can move 10% in both directions along the y-axis
        yPercent: 10,
      },
      {
        scrollTrigger: {
          trigger: heroSection.current,
          scrub: true,
          start: "top top",
          markers: false,
        },
        yPercent: -10,
        scale: 1.2,
        ease: "none",
      }
    );
  }, []);

  const headerImage = useMemo(() => {
    return data.allFile.nodes.find(
      (file) => file.relativePath === pageContext.frontmatter.headerImage
    );
  }, [data.allFile.nodes, pageContext.frontmatter.headerImage]);

  return (
    <div>
      <StaticAssetsProvider assets={data.allFile.nodes}>
        <HTMLHead
          title={pageContext?.frontmatter?.title as string | undefined}
        />
        <BaseNavigation />
        <div ref={heroSection} className="mb-20">
          <div className="relative mb-20">
            <div className="absolute w-full h-full overflow-hidden flex flex-col justify-center overflow-hidden">
              <div ref={heroImageRef} className="w-full h-full">
                {headerImage?.childImageSharp?.gatsbyImageData && (
                  // TODO: Put actual alt tag here
                  <GatsbyImage
                    alt="HeaderImage"
                    className="w-full h-full object-cover"
                    image={headerImage?.childImageSharp?.gatsbyImageData}
                  />
                )}
              </div>
            </div>
            <div className="absolute h-full w-full bg-alchem-dark bg-opacity-50" />
            <div className="absolute bottom-0 h-full w-full bg-alchem-dark bg-opacity-5" />
            <div className="container h-screen relative flex justify-center">
              <div className="flex flex-col justify-center">
                <h1 className="text-8xl leading-[1.15em] font-semibold mb-8 text-white">
                  {pageContext?.frontmatter?.title}
                </h1>
                <p className="font-semibold text-2xl max-w-4xl">
                  {pageContext?.frontmatter?.subtitle}
                </p>
              </div>
            </div>
          </div>
        </div>

        <main>
          {data?.mdx && <MDXRenderer>{data.mdx.body}</MDXRenderer>}
          {children}
        </main>

        <BaseFooter />
      </StaticAssetsProvider>
    </div>
  );
};

export default FocusPageLayout;
